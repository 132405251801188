<template>
	<div>
		<v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
		<v-container fluid class="my-0" v-else>
			<AppNavbar class="ma-0 pa-0" />
			<v-container>
				<v-layout row wrap justify-center align-center>
					<v-flex xs12 md4 lg4 xl4 sm12 class="px-1">
						<v-autocomplete outlined dense :label="$store.getters.language.data.search + '...'" class="mt-7"
							:items="items" item-text="item_name" item-value="item_id" :search-input.sync="search_item"
							v-model="item_id" :filter="filter_object"
							@keyup.enter="add_item_to_invoice(items.find(obj => obj.item_name == search_item || obj.item_barcode == search_item).item_id)">
							<template v-slot:item="{item}">
								<span style="width: 100%;" @click="add_item_to_invoice(item.item_id)">
									{{ item.item_name }} </span>
							</template>
						</v-autocomplete>
					</v-flex>

					<v-flex xs12 md2 lg2 xl2 sm12 class="px-1">
						<v-btn block color="primary" @click="item_dialog = true">
							{{ $store.getters.language.data.items.add_btn }}
						</v-btn>
					</v-flex>

					<v-flex xs12 md1 lg1 xl1 sm12 class="px-1 text-center">
						<h3> #{{ invoices.invoice_id }} <v-btn icon
								v-if="invoices.invoice_id && invoices.invoice_status == 'qist'"
								:to="'/qist/' + invoices.invoice_id">
								<v-icon> mdi-currency-usd </v-icon>
							</v-btn>
						</h3>
					</v-flex>

					<v-flex xs12 md2 lg2 xl2 sm12 class="px-1 text-center">
						<v-icon class="mx-3" @click="search_dialog = true"> mdi-magnify </v-icon>
						<v-icon class="mx-3" @click="clear_all()"> mdi-broom </v-icon>
					</v-flex>

				</v-layout>
				<!-- <v-app-bar-title>

					<span style="font-size: 18px;"> {{ $store.getters.language.app_name }} </span>
				</v-app-bar-title> -->

			</v-container>

			<v-layout row wrap>
				<v-flex xs12 md2 lg2 xl2 sm12 class="pa-1">
					<v-card elevation="0">
						<v-card-text :style="`height: ${dynamic_height}px;`">
							<v-autocomplete :label="$store.getters.language.data.invoices.customer_name" outlined dense
								:items="customers" item-text="customer_name" item-value="customer_id"
								v-model="invoices.customer_id"></v-autocomplete>
							<!-- <v-autocomplete label="Account Name" outlined dense :items="accounts" item-text="account_name" item-value="account_id" v-model="invoices.account_id"></v-autocomplete> -->
							<v-text-field outlined v-model="invoices.invoice_profit_percentage"
								:label="$store.getters.language.data.invoices.invoice_profit_percentage" dense
								class="mx-1" required prepend-inner-icon="mdi-percent">
							</v-text-field>
							<v-autocomplete :label="$store.getters.language.data.invoices.user_name" outlined dense
								:items="users" item-text="user_name" item-value="user_id" v-model="invoices.user_id">
							</v-autocomplete>
							<!-- <v-select label="Status" outlined dense :items="sell_types" item-text="text" item-value="value" v-model="invoices.invoice_status" @change="change_invoice_status()"></v-select> -->
							<v-textarea :label="$store.getters.language.data.invoices.invoice_note" outlined dense
								v-model="invoices.invoice_note" rows="3"></v-textarea>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex xs12 md7 lg7 xl7 sm12 class="py-1">
					<v-card elevation="0">
						<v-card-text
							:style="`height: ${dynamic_height}px; max-height: ${dynamic_height}px; overflow: auto;`"
							class="categories-and-items">
							{{ $store.getters.language.data.categories.title }}
							<div style="width: 100%; overflow-x: auto; white-space: nowrap;" class="categories mt-n1">
								<v-chip-group>
									<v-chip outlined class="ma-1" v-for="item in categories" :key="item.category_id"
										@click="category_id != item.category_id ? category_id = item.category_id : category_id = null">
										{{ item.category_name }} </v-chip>
								</v-chip-group>
							</div>
							<v-divider></v-divider>
							<v-layout row wrap>
								<v-flex xs12 md3 lg3 xl3 sm12 class="px-2"
									v-for="item in (filtered_items).filter(obj => obj.item_btn_show == 'enable')"
									:key="item.item_id">
									<v-card elevation="5" style=" border-radius: 10px;" class="text-center mt-5 pa-3">
										<!-- <v-img :src="$image_url + 'file_attachments/serve_file/' + item.item_image" contain v-if="show_image" height="100px"></v-img> -->
										<h3 class="mt-1"> {{ item.item_name }} </h3>
										<h4 class="mt-1 pb-2">
											{{ priceCollimation((item.item_sell_price_retail+(item.item_sell_price_retail*(invoices.invoice_profit_percentage/100)))).toLocaleString() }}
											{{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </h4>
										<h5>{{item.item_available_qty}} {{item.item_unit}}
											{{$store.getters.language.data.invoices.available}} </h5>
										<v-btn :disabled="(item.item_available_qty < 1)" color="primary" block small
											@click="add_item_to_invoice(item.item_id)">
											{{$store.getters.language.data.invoice_items.add_btn}}</v-btn>
									</v-card>
								</v-flex>
							</v-layout>
						</v-card-text>
					</v-card>
				</v-flex>
				<v-flex xs12 md3 lg3 xl3 sm12 class="pa-1">
					<v-card elevation="0"
						:style="`height: ${dynamic_height}px; overflow-y: auto; background-color: #F2F7FB;`">
						<v-card-title>
							<h5>
								<span>
									{{ $store.getters.language.data.invoices.invoice }}
								</span>
								<small class="ms-15 ps-15" v-if="invoices.invoice_date">
									{{ new Date(invoices.invoice_date).toISOString().split('T')[0] }} </small>
							</h5>
						</v-card-title>
						<v-divider></v-divider>
						<v-card-text :style="`height: ${dynamic_height - 233}px; overflow-y: auto;`"
							class="invoice-items">
							<v-layout row wrap style=" border-radius: 10px;" class="mb-1" v-for="item in invoice_items"
								:key="item.invoice_item_id">
								<!-- <v-flex xs4> -->
								<!-- <v-img :src="$image_url + 'file_attachments/serve_file/' + item.item_image" contain width="90px" height="70px"></v-img> -->
								<!-- </v-flex> -->
								<v-flex xs8 class="ps-1">
									<h3> {{ item.item_name }} </h3>
									<h4>
										{{ (item.invoice_item_sell_price * item.invoice_item_qty).toLocaleString() }}
										{{ $store.getters.store.store_payment == 'usd' ? '$' : '' }}
										<span class="float-right">
											<span class="pa-1 me-1">
												<v-icon v-ripple color="primary" class="me-3"
													:disabled="item.invoice_item_qty == 1" @click="decrease_qty(item)">
													mdi-minus-circle </v-icon>
												{{ item.invoice_item_qty }}
												<v-icon v-ripple color="primary" class="ms-3 me-0"
													@click="increase_qty(item)"> mdi-plus-circle </v-icon>
											</span>
											<div class="ms-7 mt-1 error--text px-2" v-ripple
												style="cursor: pointer; user-select: none;"
												@click="select_invoice_item(item)">
												{{ $store.getters.language.data.invoice_items.remove_btn }}
											</div>
										</span>
									</h4>
								</v-flex>
								<v-spacer></v-spacer>
							</v-layout>
						</v-card-text>
						<v-card-actions>
							<v-layout row wrap class="mx-1 px-1 py-5">
								<v-flex xs6 class="text-start">
									<h5> {{ $store.getters.language.data.total }} </h5>
								</v-flex>
								<v-flex xs6 class="text-end">
									<h4> {{ (total).toLocaleString() }}
										{{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </h4>
								</v-flex>
								<v-flex xs12 md12 lg12 xl12 sm12 class="mt-1 px-1">
									<v-row>
										<v-col cols="12" v-if="!invoices.invoice_id">
											<v-btn block color="success" @click="add_invoice(false, '')"
												:disabled="buttonDisable"> {{ $store.getters.language.data.sell }}
											</v-btn>
										</v-col>
										<v-col cols="12" v-else>
											<v-btn block color="primary" @click="update_invoices()"
												:disabled="buttonDisable">
												{{ $store.getters.language.data.invoices.update_btn }} </v-btn>
										</v-col>
										<template v-if="$store.getters.store.store_print_type == 'a4_mini'">
											<template v-if="!invoices.invoice_id">
												<v-col cols="6" class="mx-0 px-2">
													<v-btn small color="success" block @click="add_invoice(true, 'a4')">
														{{ $store.getters.language.data.sell_print }}/A4 </v-btn>
												</v-col>
												<v-col cols="6" class="mx-0 px-2">
													<v-btn small color="success" block
														@click="add_invoice(true, 'mini')">
														{{ $store.getters.language.data.sell_print }}/Mini </v-btn>
												</v-col>
											</template>
											<template v-else>
												<v-col cols="6" class="mx-0 px-2">
													<v-btn color="primary" @click="print_invoice('a4')">
														{{ $store.getters.language.data.print }}/A4 </v-btn>
												</v-col>
												<v-col cols="6" class="mx-0 px-2">
													<v-btn color="primary" @click="print_invoice('mini')">
														{{ $store.getters.language.data.print }}/mini </v-btn>
												</v-col>
											</template>
										</template>
										<template v-else>
											<v-col cols="12" class="mx-0 px-2" v-if="!invoices.invoice_id">
												<v-btn block color="success"
													@click="add_invoice(true, $store.getters.store.store_print_type)"
													:disabled="buttonDisable"> {{ $store.getters.language.data.sell }}
													&amp; {{ $store.getters.language.data.print }} </v-btn>
											</v-col>
											<v-col cols="12" class="mx-0 px-2" v-else>
												<v-btn block color="primary"
													@click="print_invoice($store.getters.store.store_print_type)"
													:disabled="buttonDisable"> {{ $store.getters.language.data.print }}
												</v-btn>
											</v-col>
										</template>
									</v-row>
								</v-flex>
							</v-layout>
						</v-card-actions>
					</v-card>
				</v-flex>
			</v-layout>
		</v-container>

		<v-dialog v-model="delete_dialog" persistent max-width="400">
			<v-card>
				<v-card-title>
					{{$store.getters.language.data.invoice_items.delete_question}}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn text class="elevation-0" @click="delete_dialog = false">
						{{$store.getters.language.data.invoices.cancel_btn}}
					</v-btn>
					<v-btn color="error" class="elevation-0" @click="deleteInvoiceItem(selected_invoice_item)">
						{{$store.getters.language.data.invoices.yes_btn}}
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>

		<v-dialog persistent v-model="qist_dialog" max-width="450">
			<v-card>
				<v-card-title>
					{{ $store.getters.language.data.qist.number_of_qist }}
				</v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-5">
					<v-text-field label="Invoice qist count"
						@change="calculate_qist_list(total, invoices.invoice_qist_count)" outlined dense
						v-model="invoices.invoice_qist_count" type="number"></v-text-field>
					<v-simple-table fixed-header>
						<template v-slot:default>
							<thead>
								<tr>
									<th> {{ $store.getters.language.data.qist.qist_amount }} </th>
									<th> {{ $store.getters.language.data.qist.qist_date }} </th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(item, index) in qist_list" :key="index">
									<td> {{ (item.qist_amount).toLocaleString() }}
										{{ $store.getters.store.store_payment == 'usd' ? '$' : '' }} </td>
									<td> {{ new Date(item.qist_date).toISOString().split('T')[0] }} </td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
					<v-btn block color="primary" @click="add_qist()"> {{ $store.getters.language.data.ok }} </v-btn>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="search_dialog" max-width="400">
			<v-card>
				<v-card-title> {{ $store.getters.language.data.search }} </v-card-title>
				<v-divider></v-divider>
				<v-card-text class="mt-5">
					<v-layout row wrap>
						<v-flex xs10 class="px-1">
							<v-text-field outlined dense label="Invoice ID" v-model="search_invoice"
								@keyup.enter="search_to_invoice()"></v-text-field>
						</v-flex>
						<v-flex xs2>
							<v-btn color="primary" @click="search_to_invoice()">
								<v-icon> mdi-magnify </v-icon>
							</v-btn>
						</v-flex>
					</v-layout>
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-dialog v-model="item_dialog" max-width="1100">
			<v-card>
				<v-card-title></v-card-title>
				<v-card-text class="mt-1">
					<itemsList />
				</v-card-text>
			</v-card>
		</v-dialog>

		<v-snackbar v-model="snackbar.value" :color="snackbar.color">
			{{snackbar.text}}
		</v-snackbar>
	</div>
</template>

<script>
	import invoice_request from '../../requests/invoices.request'
	import invoice_items_request from '../../requests/invoice_items.request'
	import qist_request from '../../requests/qist.request'
	import itemsList from '../Items/List.vue'
	import AppNavbar from '../../components/Navbar.vue'
	export default {
		components: {
			itemsList,
			AppNavbar
		},
		data() {
			return {
				loading: false,
				category_id: null,
				item_id: null,
				search_item: '',
				invoices: {
					user_id: null,
					account_id: 1,
					invoice_status: 'naqd',
					invoice_profit_percentage: 20,
				},
				invoice_items: [],
				qist_list: [],
				snackbar: {
					value: false,
					text: '',
					color: ''
				},
				show_image: false,
				search_invoice: null,
				search_dialog: false,
				item_dialog: false,
				qist_dialog: false,
				delete_dialog: false,
				selected_invoice_item: {},
			}
		},
		computed: {
			categories() {
				return this.$store.getters.categories_list
			},
			items() {
				return this.$store.getters.items_list
			},
			filtered_items() {
				let i = this.items.filter(obj => {
					return this.category_id ? (obj.category_id == this.category_id) : true
				})

				// check if item is available
				i = i.filter(obj => obj.item_available_qty > 0)

				return i
			},
			dynamic_height() {
				return window.innerHeight - 90;
			},
			customers() {
				return this.$store.getters.customers_list;
			},
			accounts() {
				return this.$store.getters.accounts_list;
			},
			users() {
				return this.$store.getters.users_list;
			},
			sell_types() {
				var sell_type = this.$store.getters.store.store_sell_type
				if (!this.invoices.customer_id) {
					return [{
						text: 'Cash',
						value: 'naqd'
					}]
				} else if (sell_type == 'all') {
					return [{
							text: 'Cash',
							value: 'naqd'
						},
						{
							text: 'Loan',
							value: 'qarz'
						},
						{
							text: 'Diposit',
							value: 'qist'
						}
					]
				} else if (sell_type == 'naqd') {
					return [{
						text: 'Cash',
						value: 'naqd'
					}, ]
				} else if (sell_type == 'qarz') {
					return [{
							text: 'Cash',
							value: 'naqd'
						},
						{
							text: 'Loan',
							value: 'qarz'
						}
					]
				} else if (sell_type == 'qist') {
					return [{
							text: 'Cash',
							value: 'naqd'
						},
						{
							text: 'Diposit',
							value: 'qist'
						}
					]
				}
			},
			total() {
				return this.invoice_items.reduce((acc, n) => acc + (n.invoice_item_sell_price * n.invoice_item_qty), 0)
			},
			buttonDisable() {
				return !this.invoices.customer_id || !this.invoices.account_id || !this.invoice_items.length
			}
		},
		mounted() {
			this.invoices.user_id = this.$store.getters.auth.user_id
			this.invoices.account_id = this.accounts.find(obj => obj.account_is_default == 'true').account_id
			this.invoices.invoice_status = 'naqd'
			const show_item = localStorage.getItem('void_store_show_image');
			if (show_item && show_item == 'true') {
				this.show_image = true;
			} else {
				localStorage.setItem('void_store_show_image', false)
			}

			if (this.$route.params.id) {
				this.search_invoice = this.$route.params.id
				this.search_to_invoice()
			}
		},
		methods: {
			priceCollimation(price) {
				// price = price.toString();
				// if (price.includes('.')) {
				// 	price = Math.round(Number(price)).toString();
				// }
				// var num = Number(price.substring(price.length - 3));
				// price -= num;
				// price = Number(price);
				// var arr = [0, 250, 500, 750, 1000];
				// if (!arr.includes(num)) {
				// 	var result = (num % 250) > (250 - (num % 250)) ? "high" : "low";
				// 	if (result == "high") {
				// 		for (let index = 1; index < arr.length; index++) {
				// 			let temp = arr[index] - num;
				// 			if (temp > 0) {
				// 				num = Number(num) + Number(temp);
				// 				return Number(Number(price) + Number(num));
				// 			}
				// 		}
				// 	} else {
				// 		for (let index = 0; index < arr.length; index++) {
				// 			let temp = arr[index] - num;
				// 			if (temp > 0) {
				// 				var x = (num - (250 - (temp - num))) * -1;
				// 				num -= x;
				// 				return Number(Number(price) + Number(num));
				// 			}
				// 		}
				// 	}
				// }
				// return Number(Number(price) + Number(num));

				return parseFloat(price.toFixed(0));
			},
			clear_all() {
				this.invoices = {
					user_id: this.$store.getters.auth.user_id,
					account_id: this.accounts.find(obj => obj.account_is_default == 'true').account_id,
					invoice_status: 'naqd',
					invoice_profit_percentage: 20,
				}
				this.invoice_items = []
				this.category_id = null
				this.item_id = null
			},
			filter_object(item, query_text) {
				return item.item_name.toLowerCase().includes(query_text.toLowerCase()) || item.item_barcode.toLowerCase()
					.includes(query_text.toLowerCase())
			},
			add_invoice(printable, print_type) {
				if (this.invoices.user_id && this.invoices.account_id && this.invoices.invoice_status) {
					this.invoices.store_id = this.$store.getters.store.store_id;
					this.invoices.invoice_date = new Date().toISOString().split('T')[0];
					invoice_request.createInvoices({
						invoices: this.invoices,
						invoice_items: this.invoice_items
					}).then(async r => {
						this.invoices = r.data.new_data;
						if (this.invoices.invoice_status == 'qist') {
							this.qist_dialog = true;
						} else {
							if (printable) {
								window.open('/prints/print_invoice/' + print_type + '/' + this.invoices
									.invoice_id)
							}
							this.clear_all();
						}
						this.snackbar = {
							text: this.$store.getters.language.data.messages.add_success,
							color: 'success',
							value: true
						}
					}).catch(err => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.failed,
							color: 'error',
							value: true
						}
					})
				} else {
					this.snackbar = {
						text: this.$store.getters.language.data.messages.fill_information_correctly,
						color: 'warning',
						value: true
					}
				}
			},
			add_item_to_invoice(item_id) {
				const item = this.items.find(obj => obj.item_id == item_id);
				if (item) {
					const invoice_item = this.invoice_items.find(obj => obj.item_id == item_id)
					if (!invoice_item) {
						const new_invoice_item = {
							item_id: item.item_id,
							invoice_item_qty: 1,
							invoice_item_sell_price: this.priceCollimation(item.item_sell_price_retail + (item.item_sell_price_retail * (this
								.invoices.invoice_profit_percentage / 100))),
							invoice_item_cost_price: this.priceCollimation(item.item_sell_price_retail),
							item_image: item.item_image,
							item_name: item.item_name
						}

						if (this.invoices.invoice_id) {
							invoice_items_request.createInvoiceItems({
								item_id: item.item_id,
								invoice_id: this.invoices.invoice_id,
								invoice_item_qty: 1,
								invoice_item_sell_price: this.priceCollimation(item.item_sell_price_retail + (item
									.item_sell_price_retail * (this.invoices.invoice_profit_percentage / 100))),
								invoice_item_cost_price: this.priceCollimation(item.item_sell_price_retail),
							}).then(r => {
								if (r.status == 200) {
									this.invoice_items.push(r.data.new_data)
									this.snackbar = {
										text: this.$store.getters.language.data.messages.add_success,
										color: 'success',
										value: true
									}
								}
							}).catch(err => {
								this.snackbar = {
									text: this.$store.getters.language.data.messages.failed,
									color: 'error',
									value: true
								}
							})
						} else {
							this.invoice_items.push(new_invoice_item)
						}
					} else {
						if (this.invoices.invoice_id) {
							invoice_items_request.updateInvoiceItems(invoice_item.invoice_item_id, {
								invoice_id: invoice_item.invoice_id,
								item_id: invoice_item.item_id,
								invoice_item_qty: invoice_item.invoice_item_qty + 1,
								invoice_item_sell_price: this.priceCollimation(item.item_sell_price_retail + (item
									.item_sell_price_retail * (this.invoices.invoice_profit_percentage / 100))),
								invoice_item_cost_price: this.priceCollimation(item.item_sell_price_retail),
							}).then(r => {
								if (r.status == 200) {
									++invoice_item.invoice_item_qty
								}
							})
						} else {
							++invoice_item.invoice_item_qty
						}
					}
				} else {
					this.snackbar = {
						text: this.$store.getters.language.data.messages.not_found,
						color: 'warning',
						value: true
					}
				}
				this.search_item = '';
			},
			add_qist() {
				if (this.invoices.invoice_id && Number(this.invoices.invoice_qist_count) > 0) {
					this.update_invoices();
					qist_request.createQistList({
						list: this.qist_list
					}).then(r => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.add_success,
							color: 'success',
							value: true
						}
						window.open('/prints/print_invoice/' + this.invoices.invoice_id)
						this.clear_all();
						this.qist_dialog = false
					}).catch(e => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.failed,
							color: 'error',
							value: true
						}
					});
				} else {
					this.snackbar = {
						text: this.$store.getters.language.data.messages.fill_information_correctly,
						color: 'warning',
						value: true
					}
				}
			},
			increase_qty(item) {
				if (this.invoices.invoice_id) {
					invoice_items_request.updateInvoiceItems(item.invoice_item_id, {
						invoice_id: item.invoice_id,
						item_id: item.item_id,
						invoice_item_qty: item.invoice_item_qty + 1,
						invoice_item_sell_price: item.invoice_item_sell_price,
					}).then(async r => {
						++item.invoice_item_qty
						if (this.invoices.invoice_status == 'qist') {
							const qist_amount = (this.total / this.invoices.invoice_qist_count).toFixed(0)
							await qist_request.updateQistColumn('invoice_id', this.invoices.invoice_id, {
								qist_amount
							})
						}
					}).catch(e => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.failed,
							color: 'error',
							value: true
						}
					})
				} else {
					++item.invoice_item_qty
				}
			},
			decrease_qty(item) {
				if (this.invoices.invoice_id) {
					invoice_items_request.updateInvoiceItems(item.invoice_item_id, {
						invoice_id: item.invoice_id,
						item_id: item.item_id,
						invoice_item_qty: item.invoice_item_qty - 1,
						invoice_item_sell_price: item.invoice_item_sell_price,
					}).then(async r => {
						--item.invoice_item_qty
						if (this.invoices.invoice_status == 'qist') {
							const qist_amount = (this.total / this.invoices.invoice_qist_count).toFixed(0)
							await qist_request.updateQistColumn('invoice_id', this.invoices.invoice_id, {
								qist_amount
							})
						}
					}).catch(e => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.failed,
							color: 'error',
							value: true
						}
					})
				} else {
					--item.invoice_item_qty
				}
			},
			search_to_invoice() {
				if (this.search_invoice) {
					invoice_request.searchToInvoice(this.search_invoice).then(r => {
						if (r.data.invoice) {
							this.invoices = r.data.invoice
							this.invoice_items = r.data.invoice_items
						} else {
							this.snackbar = {
								text: this.$store.getters.language.data.messages.not_found,
								color: 'error',
								value: true
							}
						}
						this.search_dialog = false
					})
				} else {
					this.snackbar = {
						text: this.$store.getters.language.data.messages.fill_information_correctly,
						color: 'warning',
						value: true
					}
				}
			},
			select_invoice_item(i) {
				this.selected_invoice_item = i;
				this.delete_dialog = true;
			},
			deleteInvoiceItem(item) {
				if (this.invoices.invoice_id) {
					invoice_items_request.deleteInvoiceItems(item.invoice_item_id).then(async r => {
						this.invoice_items = this.invoice_items.filter(obj => obj.invoice_item_id != item
							.invoice_item_id)
						this.snackbar = {
							text: this.$store.getters.language.data.messages.delete_success,
							color: 'success',
							value: true
						}
						// if (this.invoice_items.length == 0) {
						// 	if (this.invoices.invoice_status == 'qist') {
						// 		await qist_request.deleteQistColumn('invoice_id', this.invoices.invoice_id)
						// 	}
						// 	invoice_request.deleteInvoices(this.invoices.invoice_id).then(r => {
						// 		this.clear_all();
						// 		this.snackbar = {
						// 			text: this.$store.getters.language.data.messages.delete_success,
						// 			color: 'success',
						// 			value: true
						// 		}
						// 	}).catch(e => {
						// 		this.snackbar = {
						// 			text: this.$store.getters.language.data.messages.failed,
						// 			color: 'error',
						// 			value: true
						// 		}
						// 	})
						// }
						this.delete_dialog = false;
					}).catch(e => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.failed,
							color: 'error',
							value: true
						}
					})
				} else {
					this.invoice_items = this.invoice_items.filter(obj => obj.item_id != item.item_id)
					this.delete_dialog = false;
				}
			},
			calculate_qist_list(total, qist_count) {
				const list = [];
				let qist_amount = (total / qist_count).toFixed(0)
				let date = new Date(this.invoices.invoice_date);
				for (let i = 0; i < qist_count; i++) {
					date.setMonth(date.getMonth() + 1);
					list.push({
						invoice_id: this.invoices.invoice_id,
						qist_date: new Date(date),
						paid_date: new Date(date),
						qist_status: 'not_paid',
						user_id: this.$store.getters.auth.user_id,
						qist_amount: Number(qist_amount),
						qist_note: null
					})
				}
				this.qist_list = list
			},
			change_invoice_status() {
				const new_list = [];
				const new_list_to_database = [];
				for (let item of this.invoice_items) {
					const one_item = this.items.find(obj => obj.item_id == item.item_id);
					item.invoice_item_sell_price = this.invoices.invoice_status != 'qist' ? one_item
						.item_sell_price_retail : one_item.item_sell_price_deposit;
					new_list_to_database.push({
						invoice_item_id: item.invoice_item_id,
						invoice_id: this.invoices.invoice_id,
						item_id: item.item_id,
						invoice_item_qty: item.invoice_item_qty,
						invoice_item_sell_price: item.invoice_item_sell_price
					})
				}
				if (this.invoices.invoice_id) {
					this.update_invoices().then(r => {
						invoice_items_request.updateList({
							list: new_list_to_database
						}).then(async r => {
							this.snackbar = {
								text: this.$store.getters.language.data.messages.update_success,
								color: 'success',
								value: true
							}
							if (this.invoices.invoice_status == 'qist') {
								this.qist_dialog = true;
							} else {
								await qist_request.deleteQistColumn('invoice_id', this.invoices
									.invoice_id)
							}
						}).catch(e => {
							this.snackbar = {
								text: this.$store.getters.language.data.messages.failed,
								color: 'error',
								value: true
							}
						})
					})
				}
			},
			async update_invoices() {
				if (this.invoices.user_id && this.invoices.account_id && this.invoices.invoice_status) {
					await invoice_request.updateInvoices(this.invoices.invoice_id, {
						store_id: this.invoices.store_id,
						invoice_date: new Date(this.invoices.invoice_date).toISOString().split('T')[0],
						user_id: this.invoices.user_id,
						customer_id: this.invoices.customer_id,
						invoice_note: this.invoices.invoice_note,
						invoice_status: this.invoices.invoice_status,
						invoice_qist_count: this.invoices.invoice_qist_count,
						account_id: this.invoices.account_id,
						customer_address_id: this.invoices.customer_address_id,
						invoice_profit_percentage: this.invoices.invoice_profit_percentage
					}).then(r => {
						this.snackbar = {
							text: this.$store.getters.language.data.messages.update_success,
							color: 'success',
							value: true
						}
					})
				} else {
					this.snackbar = {
						text: this.$store.getters.language.data.messages.fill_information_correctly,
						color: 'warning',
						value: true
					}
				}
			},
			print_invoice(print_type) {
				window.open('/prints/print_invoice/' + print_type + '/' + this.invoices.invoice_id)
			}
		},
		watch: {
			show_image(value) {
				localStorage.setItem('void_store_show_image', value);
			}
		}

	}
</script>

<style scoped>
	/* width */
	.categories::-webkit-scrollbar,
	.categories-and-items::-webkit-scrollbar,
	.invoice-items::-webkit-scrollbar {
		width: 8px;
		height: 10px;
	}

	/* Track */
	.categories::-webkit-scrollbar-track,
	.categories-and-items::-webkit-scrollbar-track,
	.invoice-items::-webkit-scrollbar-track {
		box-shadow: inset #ebf5fc;
		border-radius: 10px;
	}

	/* Handle */
	.categories::-webkit-scrollbar-thumb,
	.categories-and-items::-webkit-scrollbar-thumb,
	.invoice-items::-webkit-scrollbar-thumb {
		background: #92c5e9;
		border-radius: 10px;
	}

	.v-slide-item--active {
		border-color: #ff6585;
	}
</style>